import { Box, Flex, HStack, Spinner, Text } from "@chakra-ui/react"
import DocPost from "@cms/components/docs/DocPost"
import { NavBar, navSectionHeaders } from "@cms/components/docs/NavBar"
import PostThumbnail from "@cms/components/docs/PostThumbnail"
import { TableOfContents } from "@cms/components/docs/TableOfContents"
import { Post } from "@cms/components/types/Post"
import { CmsCategoryTypes } from "@cms/constants/cmsConstants"
import GlobalFooter from "@common/components/GlobalFooter"
import GlobalHeader from "@common/components/GlobalHeader"
import LandingContainer from "@common/components/LandingContainer"
import LandingSection from "@common/components/LandingSection"
import { useSearchContext } from "@common/components/SearchContextProvider"
import { SectionBg } from "@common/utils/theme"
import Head from "next/head"
import { useEffect, useState } from "react"

interface Props {
	post: Post
}

const DocsPage = ({ post }: Props) => {
	const [currentPost, setCurrentPost] = useState<Post>()
	const [searchParams, setSearchParams] = useState(undefined)

	const {
		state: { activeDocSection, searchResults, isSearching },
		actions: {
			setSearchConfig,
			setActiveCategory,
			setActiveDocSection,
			setSearchTerms,
		},
	} = useSearchContext()

	useEffect(() => {
		setSearchParams(new URLSearchParams(window.location.search))
	}, [setSearchParams])

	useEffect(() => {
		const terms = searchParams?.get("search")?.split(" ")
		if(terms) {
			setSearchTerms(terms)
		}
	}, [searchParams, setSearchTerms])

	useEffect(() => {
		const categories = [CmsCategoryTypes.Support]
		setSearchConfig({
			categories: categories,
		})

		setActiveCategory(CmsCategoryTypes.Support)
	}, [setSearchConfig, setActiveCategory])

	useEffect(() => {
		setCurrentPost(post)
	}, [post, setCurrentPost])

	/**
	 * Handler for when one of the nav bar headings is selected
	 * @param section the Sanity documentationSection
	 */
	const docSectionSelectionHandler = (section: string) => {
		setActiveDocSection(section)

		// clear other values
		setCurrentPost(null)
		setSearchTerms([])
	}

	/**
	 * Handler for the nav bar search text field
	 * @param event the HTMLInputElement
	 */
	const searchTermHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const words = event.target.value.split(" ")
		setSearchTerms(words)

		// clear doc section selection
		setActiveDocSection(null)
	}

	/**
	 * Handler for when a breadcrumb parent is selected ("Docs" or nav bar parent)
	 * (clear search terms, select documentation section)
	 * @param docSection the document section to filter by
	 */
	const breadcrumbClickHandler = (docSection: string) => {
		setSearchTerms([])
		setCurrentPost(null)
		setActiveDocSection(docSection)
	}

	/**
	 * Handler for when one of the "Document Type" breadcrumbs is selected
	 * (clear documentation section, search for specific document type)
	 * @param docType the document type selected
	 */
	const breadcrumbTopicHandler = (docType: string) => {
		setSearchTerms(docType.split(" "))

		// clear other values
		setCurrentPost(null)
		setActiveDocSection("*")
	}

	// the currently selected section header (may be null)
	const navBarSectionHeader = navSectionHeaders.find(
		(header) => header.value === activeDocSection
	)

	return (
		<>
			<Head>
				<title>Teleseer | Docs</title>
			</Head>

			<GlobalHeader />

			<LandingContainer backgroundColor="bg_lighter">
				<LandingSection
					fullWidth
					borderRadius={"none"}
					sectionBgColor={SectionBg.white}
					color="section_color_dark"
					justifyContent="top"
					className="blog-post"
				>
					<Flex
						direction="row"
						width="100%"
						gap={24}
						alignItems="flex-start"
						flexWrap="wrap"
					>
						{/* flex section 1 */}
						<Box className="nav-bar">
							<NavBar
								currentPost={currentPost}
								onSearchTermChange={searchTermHandler}
								onDocSectionClick={docSectionSelectionHandler}
								searchBarMaxWidth={"300px"}
							/>
						</Box>						

						{/* flex section 2 */}
						<Box 
							maxWidth={{ base: "100%", xl: "66%" }}
							width={ isSearching ? "100%" : "inherit" }
							className="content">
						{/* if there is a current selection, search results will not appear within middle panel */}
						{currentPost ? (
							<HStack alignItems="flex-start">
								<Box maxWidth={{ base: "100%", xl: "70%" }}>
									<DocPost
										post={currentPost}
										onBreadcrumbClick={breadcrumbClickHandler}
										onTopicClick={breadcrumbTopicHandler}
									/>
								</Box>
								<TableOfContents post={currentPost} />
							</HStack>
						) : isSearching ? (
							<Box textAlign="center">
								<Spinner size="xl" mt={16} />
							</Box>
						) : (
							<Flex 
								direction={"column"} 
								gap={{ base: "16px" }} 
								alignItems="flex-start"
								flexWrap="wrap" 
								>
								<Box pb={6} ml={10} mb={6} mr={8} borderBottom="1px solid #eee">
									{activeDocSection && activeDocSection !== "*" ? (
										<HStack justifyContent="space-between">
											<Box>
												<h1 style={{ textAlign: "left", marginBottom: "10px" }}>
													{navBarSectionHeader?.title}
												</h1>
												<Text>{navBarSectionHeader?.description}</Text>
											</Box>
											<Text fontSize={64}>{navBarSectionHeader?.icon}</Text>
										</HStack>
									) : (
										<>
											<h1 style={{ textAlign: "left", marginBottom: "10px" }}>
												Teleseer Documentation
											</h1>
											<Text>
												Open a section below to learn more about Teleseer.
											</Text>
											<Box className="filler" width="300px" />
										</>
									)}
								</Box>
								{searchResults?.length > 0 ? (
									searchResults?.map((post: Post) => {
										return <PostThumbnail key={post.slug} post={post} />
									})
								) : (
									<Text ml={"40px"}>
										There are no posts matching your search criteria.
									</Text>
								)}
							</Flex>
						)}
						</Box>
					</Flex>
				</LandingSection>
			</LandingContainer>

			<GlobalFooter />
		</>
	)
}

export default DocsPage
