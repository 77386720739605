import { Box, Flex, HStack, Link, Text, VStack } from "@chakra-ui/react"
import { SanityCategoryTypes } from "@cms/constants/cmsConstants"
import { getFormattedDate } from "@cms/utils/sanityApi"
import { PortableText } from "@portabletext/react"
import Image from "next/image"
import { getTags } from "../common"
import { Post, SanityTag } from "../types/Post"
import NextLink from "next/link"

export const documentTypes = {
	general: "General",
	guide: "Guide",
	how_to: "How To",
}
interface Props {
	post: Post
}

// this component represents a single post thumbnail/summary that appears on the main page
const PostThumbnail = ({ post }: Props) => {
	const {
		slug,
		author,
		authorImgUrl,
		title,
		summary,
		estimatedReadingTime,
		mainImageUrl,
		mainImageAlt,
		componentTags = [],
		documentType,
		publishedAt,
	} = post

	const documentTypeTags: SanityTag[] = [
		{ label: documentTypes[documentType], value: documentType },
	]

	const readTime =
		estimatedReadingTime === 0 || estimatedReadingTime === 1
			? "1 min"
			: `${estimatedReadingTime} min`

	return (
		<NextLink href={`/docs/${slug}`} passHref legacyBehavior>
			<Link
				sx={{
					textDecoration: "none",
					_hover: {
						textDecoration: "none",
					},
					"&:hover .blog_post_thumbnail": {
						transition: "transform .2s",
						transform: "scale(1.025)",
					},
					"&:hover .read_more": {
						opacity: 1,
						paddingRight: "6px",
					},
				}}
			>
				<Box
					background="white"
					p={8}
					pb={9}
					borderRadius={6}
					position="relative"
					sx={{
						cursor: "pointer",
						"&:hover .blog_post_thumbnail": {
							transition: "transform .2s",
							transform: "scale(1.025)",
						},
					}}
				>
					<Flex
						direction={{ base: "column", xl: "row" }}
						gap={{ base: 4, lg: 12 }}
						justifyContent="space-between"
						alignItems={"flex-start"}
					>
						{/* title and content */}
						<VStack maxWidth={450}>
							<Box p={2} pt={0}>
								{/* author avatar */}
								{authorImgUrl && post._type !== SanityCategoryTypes.Support && (
									<Flex alignItems="center" gap={2} mt={-4}>
										<Image
											className="blog_post_avatar"
											src={authorImgUrl}
											width={30}
											height={30}
											alt={author}
											style={{
												backgroundSize: "cover",
												backgroundPosition: "top center",
												borderRadius: "50%",
												width: "30px",
												height: "30px",
											}}
										/>
										<Text fontSize="14px" fontWeight="700">
											{author}
										</Text>
									</Flex>
								)}
								<Text
									fontSize="24px"
									fontWeight={700}
									mb={4}
									lineHeight="24px"
									mt={post._type !== SanityCategoryTypes.Support && 4}
								>
									{title}
								</Text>

								{/* summary content */}
								<Box noOfLines={3}>
									<PortableText value={summary} />
								</Box>
								<HStack
									mt={3}
									alignItems="flex-start"
									className="thumbnail-metadata"
								>
									{/* dates for release notes */}
									<Text sx={{ textWrap: "noWrap" }}>
										{getFormattedDate(publishedAt)}
									</Text>
									<Text fontWeight="bold">·</Text>
									<Text sx={{ textWrap: "noWrap" }}>{readTime} read</Text>
									<Text fontWeight="bold">·</Text>
									<Box>
										{getTags(post._type, documentTypeTags, componentTags)}
									</Box>
								</HStack>
							</Box>
						</VStack>
						{/* thumbnail image */}
						{mainImageUrl && (
							<Box alignSelf={"center"}>
								<Image
									className="blog_post_thumbnail"
									src={mainImageUrl}
									width={300}
									height={160}
									alt={mainImageAlt ?? "thumbnail"}
									style={{
										position: "relative",
										borderRadius: "8px",
										width: "auto",
										height: "auto",
										maxWidth: "300px",
										maxHeight: "160px",
									}}
								/>
							</Box>
						)}
					</Flex>
				</Box>
			</Link>
		</NextLink>
	)
}

export default PostThumbnail
