import { Box, HStack, Link, Text, VStack } from "@chakra-ui/react"
import { IoIosList } from "react-icons/io"

import { Post } from "../types/Post"
import useViewport from "@common/hooks/useViewport"

interface Props {
	post: Post
}

interface Heading {
	style: string
	children: [
		{
			text: string
		}
	]
}

const headerStyles = {
	h2: {
		marginLeft: "0",
		fontWeight: "500",
	},
	h3: {
		marginLeft: "12px",
	},
	h4: {
		marginLeft: "24px",
	},
}

export const TableOfContents = ({ post }: Props) => {
	const { visualWidth } = useViewport()

	if (visualWidth < 1400) return null

	return (
		<Box 
			width="300px"
			visibility={post?.headings?.length ? "visible" : "hidden"}>
			<VStack
				width="fit-content"
				alignItems="flex-start"
				boxShadow="md"
				padding="16px"
				borderRadius="8px"
			>
				{post?.headings?.length && (
					<>
						<HStack borderBottom="1px solid" paddingBottom="12px">
							<IoIosList />
							<Text fontWeight="500">TABLE OF CONTENTS</Text>
						</HStack>
						<VStack
							alignItems="flex-start"
							style={{ marginLeft: "24px", marginTop: "12px" }}
							width="100%"
						>
							{post?.headings?.map((heading: Heading) => {
								const text = heading.children[0]?.text
								return (
									<Link
										key={heading.children[0]["_key"]}
										href={`#${text}`}
										paddingRight="24px"
										style={headerStyles[heading.style]}
										fontSize={"14px"}
									>
										{text}
									</Link>
								)
							})}
						</VStack>
					</>
				)}
			</VStack>
		</Box>
	)
}
