import { Box, Flex, Text, VStack } from "@chakra-ui/react"
import { captionStyle, portableComponents } from "@cms/components/common"
import { Post } from "@cms/components/types/Post"
import { PortableText } from "@portabletext/react"
import { useEffect, useRef } from "react"
import Breadcrumb from "./Breadcrumb"
import useViewport from "@common/hooks/useViewport"

interface Props {
	post: Post
	onBreadcrumbClick(docSection: string): void
	onTopicClick(docType: string): void
}

// this component represents the display of a single post
export default function DocPost({
	post,
	onBreadcrumbClick,
	onTopicClick,
}: Props) {
	const { mainImageCaption, body } = post
	const { visualWidth } = useViewport()
	const docRef = useRef(null)
	const isMobileNav = visualWidth < 1000
	const anchorStyle = { 
		visibility: "hidden", 
		position: "absolute", 
		top: isMobileNav ? "-16px" : "-64px", 
		left: 0 } as React.CSSProperties

	useEffect(() => {
		if(docRef.current && visualWidth < 1280) {
			docRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [body, visualWidth])

	return (
		<VStack alignItems="flex-start" position="relative">
			<Box ref={docRef} style={anchorStyle} />
			<Box mb="12px">
				<Breadcrumb post={post} onBreadcrumbClick={onBreadcrumbClick} onTopicClick={onTopicClick} />
			</Box>
			<Flex direction="row" gap={16} maxWidth="100%">
				<Box mr={6} mb={6} maxWidth="100%">
					<h1 style={{ textAlign: "left" }}>{post.title}</h1>
					{mainImageCaption && (
						<Text style={{ ...captionStyle }}>{mainImageCaption}</Text>
					)}
					<Box mt={"30px"} className="blog-post-content support">
						<PortableText value={body} components={portableComponents} />
					</Box>
				</Box>
			</Flex>
		</VStack>
	)
}
