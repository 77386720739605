import { MouseEventHandler } from "react"
import { navSectionHeaders } from "./NavBar"
import { Post } from "@cms/components/types/Post"
import { documentTypes } from "./PostThumbnail"
import { HStack, Text } from "@chakra-ui/react"

interface Props {
  post: Post
  onBreadcrumbClick(docSection: string): void
	onTopicClick(docType: string): void
}

const Breadcrumb = ({ post, onBreadcrumbClick, onTopicClick, }: Props) => {
  interface IBreadcrumb {
    title: string
    onClick: MouseEventHandler<HTMLParagraphElement>
  }

  const parentBreadCrumb: IBreadcrumb = post.documentationSection
    ? {
        title: navSectionHeaders.find(
          (header) => header.value === post.documentationSection
        )?.title,
        onClick: () => onBreadcrumbClick(post.documentationSection),
      }
    : {
        title: documentTypes[post.documentType],
        onClick: () => onTopicClick(documentTypes[post.documentType]),
      }

  const linkStyle = {
    color: "#A7A7A8",
    cursor: "pointer",

    _hover: {
      color: "inherit",
      textDecoration: "underline",
    },
  }

  return (
    <HStack>
      <Text sx={{ ...linkStyle }} onClick={() => onBreadcrumbClick("*")}>
        Docs
      </Text>
      <Text color={linkStyle.color}>&gt;</Text>
      <Text sx={{ ...linkStyle }} onClick={parentBreadCrumb.onClick}>
        {parentBreadCrumb.title}
      </Text>
      <Text color={linkStyle.color}>&gt;</Text>
      <Text>{post.title}</Text>
    </HStack>
  )
}

export default Breadcrumb